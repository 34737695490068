import React from "react"
import TitleIntro from "components/sections/titleIntro";
import { JobFrontMatter } from "types/graphql";
import {Seo} from "components/seo";
import {graphql, Link} from "gatsby";
import ArrowRight from "assets/svg/arrow-right.svg";
import BaseButton from "components/button/BaseButton";

const JobDetails = ({ data } : JobFrontMatter ) => {
    const { job : { frontmatter, html } } = data

    return (
        <>
          <Seo title={frontmatter.metaTitle as string} description={frontmatter.metaDescription} typeImage={'jobs'}/>
            <TitleIntro title={frontmatter.title as string}/>
            <section className="container mb-16 lg:mb-28 lg:px-52 flex flex-col">
                <div className="job-content" dangerouslySetInnerHTML={{ __html: html }} />
                <BaseButton
                  tag={Link}
                  to="/contact?type=job"
                  target="_blank"
                  rel="noopener"
                  role="button"
                  className="self-center arrow-left-in"
                >
                  <ArrowRight />
                  Rejoindre l'équipe
                </BaseButton>
            </section>
        </ >
    )
}

export const query = graphql`
  query GetSingleJob($slug: String) {
    job: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        slug
        title
        metaTitle
        metaDescription
      }
    }
  }
`
export default JobDetails;


